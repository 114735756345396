import React from "react"
import "./footer-styles.css"
import amarinLogo from "../../images/footer/logoAmarin.svg"
import vascepaLogo from "../../images/footer/logoVascepaEPA.svg"

const Footer = ({ siteTitle }) => (

    <footer className="footer-outer">
        <div className="footer">
          <div className="row">

            <div className="col-lg-8 footer-links">

              <a href="https://amarincorp.com/privacy_policy.html" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
              <span className="divider" aria-hidden="true">|</span><a href="https://amarincorp.com/contact" rel="noopener noreferrer" target="_blank">Contact Us</a>
              <span className="divider" aria-hidden="true">|</span><a href="https://amarincorp.com/docs/Vascepa-PI.pdf" rel="noopener noreferrer" target="_blank">Full Prescribing Information</a>
              <span className="divider" aria-hidden="true">|</span><a href="#isi-scroll" rel="noopener noreferrer">Important Safety Information</a>

              <div className="footer-text">
              VASCEPA, Amarin, REDUCE-IT, and Vascepa/Amarin logos are registered trademarks of Amarin Pharmaceuticals Ireland Limited. 
                <br/>
                © 2023 Amarin Pharmaceuticals Ireland Limited.&nbsp;&nbsp;All rights reserved.&nbsp; <span className="nowrap">VAS-03052v15&nbsp;&nbsp;07/23</span>
              </div>
            </div>
            <div id="amarinLogo" className="col-lg-2 footer-logo">
            <a id="amarinLogo"  href="https://amarincorp.com/" rel="noopener noreferrer" target="_blank"><img alt="Amarin Homepage" src={amarinLogo}/></a>

            </div>
            <div id="vascepaLogo"  className="col-lg-2 footer-logo">
                 <a id="vascepaLogo" href="/"><img alt="Homepage Button" src={vascepaLogo}/></a>
            </div>
          </div>
          <div className="footer-divider"> </div>
          This website, vascepa.com, vascepahcp.com, vascepasavings.com, truetoyourheart.com, and the corporation's website (amarincorp.com), are the only company-sanctioned websites pertaining to the Amarin group of companies or its product, VASCEPA<sup>®</sup> (icosapent ethyl) capsules. The Amarin group of companies is not responsible for material contained on other non-Amarin controlled websites or other social media sites.
        </div>
    </footer>
)



export default Footer
